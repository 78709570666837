import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/review.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6ab83a517e2b62052394893bbfd2ce67/0f98f/xbc3-title.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.37065637065637%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAf/EABYBAQEBAAAAAAAAAAAAAAAAAAEAA//aAAwDAQACEAMQAAABfSV0ztEML//EABkQAQADAQEAAAAAAAAAAAAAAAIAAREDEv/aAAgBAQABBQLudIJq8FQtZTU9XP/EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAEDAQE/AYf/xAAYEQACAwAAAAAAAAAAAAAAAAAAARESE//aAAgBAgEBPwHVwXZ//8QAGRABAAMBAQAAAAAAAAAAAAAAAAERMSFB/9oACAEBAAY/Ardi7l42Wtf/xAAaEAADAQEBAQAAAAAAAAAAAAAAARExIUGB/9oACAEBAAE/IUS6RjWUlPDDPopF39jvo//aAAwDAQACAAMAAAAQAx//xAAWEQEBAQAAAAAAAAAAAAAAAAABEQD/2gAIAQMBAT8QANS6b//EABURAQEAAAAAAAAAAAAAAAAAABEQ/9oACAECAQE/EBE//8QAHBABAAMAAgMAAAAAAAAAAAAAAQARITFRQWGR/9oACAEBAAE/EKhGAW8TpCA9NTz5XIDOJ0Ligaq4H3FqfvEpbln/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Xenoblade Chronicles 3 Title",
            "title": "Xenoblade Chronicles 3 Title",
            "src": "/static/6ab83a517e2b62052394893bbfd2ce67/af659/xbc3-title.jpg",
            "srcSet": ["/static/6ab83a517e2b62052394893bbfd2ce67/8356d/xbc3-title.jpg 259w", "/static/6ab83a517e2b62052394893bbfd2ce67/bc760/xbc3-title.jpg 518w", "/static/6ab83a517e2b62052394893bbfd2ce67/af659/xbc3-title.jpg 1035w", "/static/6ab83a517e2b62052394893bbfd2ce67/51eb8/xbc3-title.jpg 1553w", "/static/6ab83a517e2b62052394893bbfd2ce67/0f98f/xbc3-title.jpg 1920w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`It must be hard to close out an epic storyline. No matter how long it takes G.R.R. Martin to finish Game of Thrones, Tetsuya Takahashi has now failed to finish not one, but three epic series with any sort of meaningful payoff. The `}<em parentName="p">{`Xeno`}</em>{` games, now under the purview of Monolith Soft, have for one reason or another been rushed to completion every time now. `}</p>
    <p><em parentName="p">{`Xenogears`}</em>{`, despite the hate it often gets for it's second disc, remains my all time favorite JRPG. It's fusion of sci-fi and pseudo judeo-christian themes in it's story telling was far ahead of it's time and blew me away in my late teen years. It's soundtrack, artwork and combat are all incredible and stand the test of time even today. However due to an inexperienced development team, they were unable to complete the project in the allotted two years, and so told most of the back half of the story from a single chair in a spotlight. It's rough for sure, but the story still carries the game.`}</p>
    <p>{`This was just the beginning of the tale though. Next comes the `}<em parentName="p">{`Xenosaga`}</em>{` set of games. This spiritual successor to `}<em parentName="p">{`Xenogears`}</em>{` was supposed to be six games, however another team change after the release of the game caused this to change to three games, and the story really suffers because of it. The third game is a mess.`}</p>
    <p>{`Finally, with `}<em parentName="p">{`Xenoblade Chronicles 3`}</em>{` they were given the time and the resources to complete a trilogy and tell a complete story. So how does the Klaus trilogy end? By throwing away just about everything the first two games setup, and not really ending the story at all.`}</p>
    <h3>{`The Good`}</h3>
    <h4>{`Combat (for a while)`}</h4>
    <p>{`Combat for about 80% of this game is actually pretty great. It starts out very similar to the previous two games. Your party will auto-attack and you can move around and position yourself for increased damage for certain arts which are triggered by the XABY buttons, or to get in the areas your allies put on the field for other buffs. The range of arts you have access too increases overtime as you unlock and master up to twenty-five classes (two of them becoming available after beating the game). You can take the mastered arts and skills from these classes and equip three more arts alongside your current classes arts and then use Fusion Arts which combines the properties of both arts.
Furthermore you can augment your abilities with three gems and three accessories to specialize your build even more. The arts combo of Break -> Topple -> Launch -> Smash and Break -> Topple -> Daze -> Burst is back to help bring the pain. Chain attacks, which allow you to string together a massive number of arts without retaliation by the enemy, make their return as well.`}</p>
    <p>{`On top of all this, you quickly unlock the ability to `}<em parentName="p">{`interlink`}</em>{` with one of your allies and turn into an unkillable robot with it's own set of skills and arts and unlock tree. You'll eventually gain the ability to use up to two skills from your partners robot. These robots slowly get more powerful the more times you use Fusion Arts during a fight.`}</p>
    <p>{`All this is to say that your characters are very customizable, providing a ton of variety and incentivizing you to switch character classes freely and often. You can even mix and match a seventh a party member to fill in any gaps in your party composition and to teach the rest of your party new classes.`}</p>
    <p>{`As mentioned you can have seven party members throwing particle effects and sounds and flashing doodads at the screen and your enemies are doing the same as well. I remain very impressed by the Switch's ability to handle this kind of work. In my nearly 100 hours with the game, I remember exactly one slowdown, and it was during one of slower more cinematic Chain Attacks.`}</p>
    <h3>{`The Bad`}</h3>
    <h4>{`The Story`}</h4>
    <p>{`Let's start with the villains, the Moebius. This is a collection of enemies with the riveting names of: A, B, C, D, E, F, G, H, I, J, K, L, M, N, O, P, Q, R, S, T, U, V, W, X, Y, and Z. Guess who the final boss is. Do the letter's stand for anything? Yes and no. Sometimes you learn the name they originally had, sometimes you don't. Knowing the name doesn't really change anything except ruin a reveal towards the end of chapter four that you can see coming mile away if you pay any attention at all to character models and a few other reveals along the way. Every one of the Moebius enemies save for the big three, are dressed in identical reddish-purple garb with the only difference being the headpiece. Since most of these are tied to specific colonies and hero acquisition, you will find out next to nothing about them or their motivations. For those lucky few who don't just die when you beat them the first time and the writers decided to give you some backstory, spoilers, you know some of them and some of them you thought were dead. There's a hint towards the end of the game that says the party should ask Z why he turned their friends into Moebius. That's the last you'll hear of that because as it turns out there is literally no conversation upon starting the final fight, it just starts, like they had no idea what to say or reveal. After defeating him, you also learn nothing from him. That's not all that's odd at the end. Outside of N and M, the two biggest, baddest Moebius are X and Y and after killing both of them there are no cutscenes or reveals. Their bodies just slowly vanish in blue wisps and you learn nothing from them either as though once again the writers got lazy.`}</p>
    <p>{`There's so much else that's not followed up on. There are so many hints that your companion Riku is definitely a `}<em parentName="p">{`big deal`}</em>{`, but that's not explored at all. Upon freeing several colonies there are hints that retaliation may be coming their way for committing treason. Outside of not getting supplies anymore from their respective colonies, they are left completely alone. Why does Ghondor, the worst character in the game (bordering on worse than Vanille in FF13), hate her mom so much? Unclear. Why does Z hang out in a movies theater? Where does that first scene take place in the games timeline? Why should we care about this game?`}</p>
    <p>{`The biggest flaw with story though is that despite assurances that this would wrap up the Klaus storyline from the first two games. The game decides right at the end, out of the blue, that instead of undoing his mistake which the party had been very verbal about wanting to do, they'd rather return the world(s) to their old status quo which, if I remember the ending of 2 correctly, means that the two worlds will just slowly fade away to nothing. No reason is given for the change of heart. Maybe the DLC will follow through and actually wrap it up, but if it does that will be an even greater sin than what we have now.`}</p>
    <h4>{`Combat`}</h4>
    <p>{`The final stretch of this game is where the combat starts to feel very samey. Despite the wide range of classes available to you, none of them feel super different within the confines of their role, of which there are three: attacker, defender, and healer. While you can augment each class with another classes arts and skills, by the end every build starts to feel very samey. No one character is truly any better than any other at any particular class, they just might be the first to use a given class. So feel free to make your brawny characters a healer and your glass cannons tanks, it makes no difference.`}</p>
    <p>{`As you reach the end of the game naturally gives your bosses, unique monsters, and even rare monsters far more hit points making chain attacks the only legitimate method of dealing the millions of points of damage you'll need to bring your foes down. The problem is unlike `}<em parentName="p">{`Xenoblade Chronicles 2`}</em>{` these chain attacks are not quick, and there's math to be done to ensure the chain continues. After each attack the game literally pauses so you can do arithmetic to make sure your hit that 100 point mark. By the end of the game you can reliably perform four orders and an Ouroboros order dealing multiple millions of points of damage and taking four to five minutes each time. The sheer amount of health enemies have at the end of the game means you need to do this three to five times just to kill something. So that's fifteen to twenty-five minutes spent doing very little and watching the same attacks and animations over and over again with no way to speed it up. In between chain attacks you'll mostly be waiting for your arts to recharge to so you can use fusion arts to turn into your robot form to evade the big attacks. This pattern is repeated for literally every single boss and unique monster I've fought without exception. No enemy I've seen has what I'd really call a unique mechanic that differentiates it from any other enemy. The only thing different the final boss does, besides having multiple forms, is have your team briefly split in half for two different short phases that you cannot possibly lose.`}</p>
    <h4>{`Progression`}</h4>
    <p>{`As mentioned earlier there are twenty-three classes (before beating the game), each tied to a specific `}<em parentName="p">{`hero`}</em>{`. With the exception of the two heroes not tied to the either of the main factions, `}<em parentName="p">{`every single one`}</em>{` is acquired the `}<em parentName="p">{`exact same way`}</em>{`. You'll stumble across their colony, one of the Moebius bad guys (more on these in a bit) will be there, you'll do one thing for the colony, you'll kill/force a retreat of the Moebius and the hero will join you. They are always the Commander of the colony, no exceptions.`}</p>
    <p>{`These heros are responsible for teaching your party their class, with one party member learning it right away. So long as you have the hero in your party, of one of your party members is that hero's class, other party members will learn it...theoretically. See, you actually only progress towards learning a class so long as the enemies you're fighting are no less than four levels below you. Doesn't really sound like much of a problem, except that completing quests or intervening in skirmishes while exploring grants you bonus XP that you can use to level up your team. Spend too much of it though, and you begin to outstrip enemies you'll be encountering which means you get no progress towards unlocking a class. Of course you can always venture far afield and out of your way to find enemies within range, but `}<em parentName="p">{`having`}</em>{` to do this is tedious. The class progress should have just been tied to the class points you acquire after defeating monsters.`}</p>
    <p>{`As with any RPG there are a metric ton of side quests in the game, most of these are pretty standard fare, however there's a certain class of quests that sucks above the rest of them: material turn in quests. There's a particular colony where that's literally all you do, turn in materials you've collected in your travels. Almost all the time there's no indication of where an item is if you need it, and you will definitely be missing some with no idea of where to go, so have fun running around gathering random materials hoping you'll satisfy those quest requirements.`}</p>
    <p>{`The world, a highlight in both of the previous entries, is also not up to snuff. Sure it's filled with giant overleveled monsters that you wont be able to kill for dozens of hours, and hidden areas and tons of collectibles, but it's so drab and boring. It befits the story, since there's been an endless war raging for countless millennia, but there was no one place that looked as great as Gormott, the Leftherian Archipelago, or the Guar Plains. Its mostly mud and destroyed ruins, which is not discordant with the story, it just lacks the character of the previous two entries.`}</p>
    <h4>{`Performance`}</h4>
    <p>{`Despite near flawless execution during combat, for some reason the engine cannot keep up during the cutscenes. Much like Monster Hunter Stories 2, you'll get used to it after the first chapter or so, but it is noticeable and I doubt most cutscenes hit 30 FPS reliably.`}</p>
    <h3>{`Final Thoughts`}</h3>
    <p>{`For a game that tries to say an awful lot, this game sure says very little. The combat is initially very fun, but it wears thin by then end. If you were hoping for a satisfying conclusion to this trilogy I'm afraid you'll have to look elsewhere. Once again Takahashi has failed to finish his story.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      